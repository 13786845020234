import React from "react"
import TextBlock from "../components/textBlock"
import Button from "../components/button"
import {Link} from "react-scroll"
import Layout from "../components/layout"
import BetaSignup from '../components/BetaSignup'
import BackgroundImage from "gatsby-background-image"
import screenshot from '../images/iphone11.png'
import SEO from "../components/seo"

const Betapage = () => {
  return (
    <>
    <SEO title="Mask Fashions > beta signup" />
    <Layout>
      <div className='beta-container'>
        <div style={{flex:1}} ><img src={screenshot} width='100%' className='beta-screenshot' /></div>
        <div style={{flex:2}}>
          <h2>Be the first to try Mask Fashions</h2>
          <div>
            <p>Mask Fashions is the first app that lets you virtually try on designer face masks.</p>
              <p style={{textDecoration: 'line-through'}}>It's launching soon on Android and iOS, and needs users!  Add your
              email below, and you'll notified when the closed beta begins (estimated to be Sept 25, 2021).</p>
            <div style={{margin: 20}}></div>
            <p>Thank you for your time and support.  (Truly!)</p>
            <div style={{margin: 20}}></div>
          </div>
          <BetaSignup />
          <div style={{margin: 40}}></div>
        </div>
      </div>
    </Layout >
    </>
  )
}

export default Betapage
