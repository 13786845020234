import * as React from "react";
import {getAuth,signInAnonymously} from 'firebase/auth';
import {addDoc,collection,getDocs,query,where} from 'firebase/firestore/lite';
import {useFormik} from "formik";
import {initializeApp} from "firebase/app";
import {getFirestore} from 'firebase/firestore/lite';

const firebaseConfig = {
	apiKey: "AIzaSyA5smm__m4k5AQQlgwGX1sGVwUTfKmKfZ0",
	authDomain: "mask-fashions.firebaseapp.com",
	databaseURL: "https://mask-fashions-default-rtdb.firebaseio.com",
	projectId: "mask-fashions",
	storageBucket: "mask-fashions.appspot.com",
	messagingSenderId: "1002061255353",
	appId: "1:1002061255353:web:79fb2d9e80af068239c168",
	measurementId: "G-BEC2Q71QXF"
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const BetaSignup = () => {
	const validate = values => {
		const errors = {};
		if (!values.email) {
			errors.email = 'Required';
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
			errors.email = 'Invalid email address';
		}

		return errors;
	};

	const formik = useFormik({
		initialValues: {
			email: ''
		},
		validate,
		onSubmit: values => {
			signInAnonymously(getAuth())
				.then(async (res) => {
					const signups = collection(db,'betasignups');
					const q = query(signups,where('email','==',values.email))
					const qres = await getDocs(q);
					if (qres.empty) {
						addDoc(signups,values)
							.then(response => console.log(response))
							.catch(error => {
								console.log(error)
								formik.setStatus('There was a problem adding you to the list.  Please refresh and try again.  If this continues please report to the email at the bottom of this page.')
							});
						formik.setSubmitting(false);
						formik.setStatus('You\'ve been added to the list!')
					} else {
						formik.setSubmitting(false);
						formik.setStatus('You\'ve already signed up.')
					}
				})
				.catch(err => {
					console.error(err)
					formik.setStatus('There was a problem adding you to the list.  Please refresh and try again.  If this continues please report to the email at the bottom of this page.')
				})
		}
	});

	return (
		<div style={{width: '100%',textAlign: 'center'}}>
			<div><form onSubmit={formik.handleSubmit}>
				<input
					id="email"
					name="email"
					type="email"
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values.email}
					style={{height: 33}}
				/>
				<button disabled={true/*{formik.isSubmitting}*/} style={{textDecoration:'line-through', marginLeft: 5, height: 33, cursor: 'pointer'}} type="submit" >Join the beta</button>
				{formik.errors.email && formik.touched.email ?
					<div style={{color: '#c58',padding: 20}}>{formik.errors.email}</div> : null}
				{formik.status ? 
					<div style={{color: '#555',padding: 20,fontWeight: 'bold'}}>{formik.status}</div> : null}
			</form>
			</div>
		</div>
	);
}

export default BetaSignup;